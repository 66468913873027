<template>
  <div class="our-store-goods-page-box">
    <SmallHeader :basic-information="basicInformation" @getKeyword="getKeyword" />
    <ShopTabs tab-active-path="/storeManagement/ourStoreGoods" @changeShopPath="changeShopPath" />
    <StoreMerchandiseList :cmp-code="basicInformation.cmpCode" :keyword="keyword" />
  </div>
</template>

<script>
import { queryShopBaseInfoDesc } from '@/http/storeManagement'
import SmallHeader from '../components/SmallHeader.vue'
import StoreMerchandiseList from '../components/StoreMerchandiseList.vue'
import ShopTabs from '@/components/ShopTabs.vue'
export default {
  components: { SmallHeader, StoreMerchandiseList, ShopTabs },
  data() {
    return {
      basicInformation: {},
      keyword: ''
    }
  },
  created() {
    if (this.$route.query.shopId) {
      // 获取表头的信息
      queryShopBaseInfoDesc(this.$route.query.shopId || '', res => {
        if (res.data) this.basicInformation = { ...res.data }
      })
    }
    this.keyword = this.$route.query.keyword || ''
  },
  methods: {
    getKeyword(value) {
      this.keyword = value
    },
    // 切换
    changeShopPath(path) {
      this.$router.push({
        path,
        query: {
          id: this.$route.query.id,
          shopId: this.$route.query.shopId
        }
      })
    }
  }
}
</script>
<style>
 .our-store-goods-page-box{
  background-color: #fff;
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 36px;
 }
</style>
